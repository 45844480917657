import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import ThankYou from "../../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../../data/restaurant-data";

export default function ThankYouPafe() {
  return (
    <Layout isLanding phoneNumber="+18145669337" phoneText="(814) 566-9337">
      <SEO title="Thank you - your demo request has been received | SpotOn " />

      <ThankYou
        phoneNumber="+18145669337"
        phoneText="(814) 566-9337"
        thankYouSubheader="You can also your local representative at"
      />

      <TestmonialReviews sectionData={restaurantTestimonials} />
    </Layout>
  );
}
